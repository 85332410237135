<template>
  <div>
    <v-row justify="center">
      <v-col cols xl="9" lg="12">
        <!--        <v-btn
          v-if="inquiryItem.inquiry_id"
          depressed
          :to="{
            name: 'InquiriesShow',
            params: { id: inquiryItem.inquiry_id }
          }"
          height="38px"
        >
          <v-icon left>mdi-chevron-left</v-icon>
          Zur Anfrage
        </v-btn>-->

        <div class="mt-10">
          <div class="mt-4 text-h4">{{ inquiryItem.name }}</div>
          <div class="mt-4 body-1">{{ inquiryItem.description }}</div>
          <div class="mt-4" v-if="inquiryItem.inquiry">
            <v-chip label small class="mt-1">
              {{ inquiryItem.inquiry.customer.name }}
              <v-tooltip top v-if="inquiryItem.inquiry.customer.customerType">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-avatar
                      :color="inquiryItem.inquiry.customer.customerType.color"
                      size="10"
                    ></v-avatar>
                  </v-btn>
                </template>
                <span>{{
                  inquiryItem.inquiry.customer.customerType.name
                }}</span>
              </v-tooltip>
            </v-chip>
          </div>
          <div class="mt-4" v-else>
            Diese Anfrage wurde gelöscht.
          </div>
        </div>

        <util-fill-form
          v-if="inquiryItem.attachable_type === 'App\\Models\\Form'"
          :valuesProp="inquiryItem.formItemValues"
          class="mt-10"
          :isShare="true"
        />
        <util-fill-documents
          v-if="inquiryItem.attachable_type === 'App\\Models\\Document'"
          :uploadRequestsProp="inquiryItem.uploadRequests"
          class="mt-10"
          :isShare="true"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: { inquiryItemProp: Object },
  data: () => ({
    loading: false,
    isValid: false,
    inquiryItem: {}
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    /*$route(to) {
      //
    }*/
  },
  created() {
    this.inquiryItem = this.inquiryItemProp;
  },
  methods: {
    downloadFile(id, name) {
      this.fileLoading = true;
      this.axios
        .get("/upload/" + id + "/download", {
          responseType: "blob"
        })
        .then(r => {
          const url = URL.createObjectURL(new Blob([r.data]));
          const fileName = name;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.fileLoading = false;
        });
    }
  }
};
</script>

<style scoped></style>
