<template>
  <div>
    <v-card>
      <v-img v-if="user.image_url" :src="user.image_url" height="270px" dark>
      </v-img>
      <v-card-title class="text-h5 pb-0">
        {{ user.name }}
      </v-card-title>
      <v-list two-line>
        <v-list-item v-if="user.label && user.type === 'extern'">
          <v-list-item-icon>
            <v-icon color="primary">
              mdi-label
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ user.label }}
            </v-list-item-title>
            <v-list-item-subtitle>Kundennummer</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="user.phone1">
          <v-list-item-icon>
            <v-icon color="primary">
              mdi-phone
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ user.phone1 }}
            </v-list-item-title>
            <v-list-item-subtitle>Telefon</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="user.phone2">
          <v-list-item-action></v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ user.phone2 }}
            </v-list-item-title>
            <v-list-item-subtitle>Mobil</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary">
              mdi-email
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ user.email }}</v-list-item-title>
            <v-list-item-subtitle>E-Mail-Adresse</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary">
              mdi-map-marker
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ user.address1 }}</v-list-item-title>
            <v-list-item-subtitle v-if="user.address2">
              {{ user.address2 }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ user.zip }} {{ user.city }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-text v-if="$hasPermissions('consultant permission')">
        <v-btn
          depressed
          block
          :to="{
            name: 'UserProfile',
            params: { id: user.id },
            query: { referer: $route.fullPath }
          }"
          >Bearbeiten</v-btn
        >
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["userProp"],
  data: () => ({
    user: {}
  }),
  created() {
    this.user = this.userProp;
  },
  watch: {
    userProp(val) {
      this.user = val;
    },
    user(val) {
      this.$emit("pass", val);
    }
  },
  computed: {},
  methods: {}
};
</script>

<style scoped></style>
