var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('router-link',{staticClass:"custom-link",attrs:{"to":!_vm.isShare
          ? _vm.isDraft
            ? { name: 'InquiriesEdit', params: { id: _vm.inquiry.id } }
            : { name: 'InquiriesShow', params: { id: _vm.inquiry.id } }
          : { name: 'SharesShow', params: { id: _vm.share.id } }}},[_c('v-card',{staticClass:"transition-ease-in-out",attrs:{"height":"100%","elevation":hover ? 12 : 2}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.inquiry.assignable},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(_vm.inquiry.assignable)?_c('v-avatar',_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"primary","size":"40"}},'v-avatar',attrs,false),on),[(!_vm.inquiry.assignable.image_url)?_c('span',[_vm._v(_vm._s(_vm.inquiry.assignable.initials))]):_c('img',{attrs:{"src":_vm.inquiry.assignable.image_url,"alt":"Profilbild"}})]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.inquiry.assignable ? _vm.inquiry.assignable.name : ""))])])],1),_c('v-list-item-content',{staticClass:"ml-6 mr-6"},[(
                  _vm.inquiry.customer && _vm.$hasPermissions('consultant permission')
                )?_c('v-list-item-subtitle',{staticClass:"mb-2"},[_c('v-chip',{staticClass:"mt-1",attrs:{"label":"","small":"","color":"grey lighten-4"}},[_vm._v(" "+_vm._s(_vm.inquiry.customer.name)+" "),(_vm.inquiry.customer.label)?_c('span',{staticClass:"ml-1"},[_vm._v("(Kdnr: "+_vm._s(_vm.inquiry.customer.label)+")")]):_vm._e(),(_vm.inquiry.customer.customerType)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-avatar',{attrs:{"right":"","color":_vm.inquiry.customer.customerType.color,"size":"5"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.inquiry.customer.customerType.name))])]):_vm._e()],1)],1):_vm._e(),(_vm.inquiry.contactPerson)?_c('v-list-item-subtitle',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.inquiry.contactPerson.name)+" ")])]}}],null,true)},[_c('span',[_vm._v("Ansprechpartner")])])],1):_vm._e(),(_vm.isShare)?_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" Anfrage ")]):_vm._e(),(_vm.inquiry.product)?_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.inquiry.product.name)+" ")]):_vm._e(),(_vm.inquiry.name)?_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.inquiry.name)+" ")]):_vm._e(),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.inquiry.description)+" ")]),(!_vm.isShare)?_c('v-list-item-subtitle',{staticClass:"mt-1"},[_vm._v(" Aktualisierung "),(_vm.inquiry.last_customer_action_at)?_c('span',[_vm._v(" am "+_vm._s(_vm._f("moment")(_vm.inquiry.last_customer_action_at,"DD.MM.YYYY HH:mm"))+" ")]):_c('span',{staticClass:"font-weight-bold"},[_vm._v(" keine ")])]):_vm._e(),(_vm.isShare)?_c('v-list-item-subtitle',{staticClass:"mt-3"},[_vm._v(" Geteilt am: "+_vm._s(_vm._f("moment")(_vm.share.created_at,"DD.MM.YYYY HH:mm"))+" ")]):_vm._e(),(_vm.isShare)?_c('v-list-item-subtitle',{staticClass:"mt-1"},[_vm._v(" Geteilt durch: "+_vm._s(_vm.share.creator.name)+" ")]):_vm._e()],1),(_vm.sortBy)?_c('v-list-item-action',[(_vm.sortBy === 'fulfillment_ratio')?_c('v-chip',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.inquiry.fulfilled_requirements)+" / "+_vm._s(_vm.inquiry.total_requirements)+" ")]):_vm._e(),(_vm.sortBy === 'last_customer_action_at')?_c('v-chip',{attrs:{"color":_vm.inquiry.is_too_long ? 'error' : 'success'}},[(_vm.inquiry.last_customer_action_at)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.inquiry.last_customer_action_at,"calendar"))+" ")]):_c('span',{staticClass:"font-weight-bold"},[_vm._v(" Keine ")])]):_vm._e()],1):_vm._e()],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }