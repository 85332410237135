import { render, staticRenderFns } from "./documents-tree-view.vue?vue&type=template&id=64d07b03&scoped=true&"
import script from "./documents-tree-view.vue?vue&type=script&lang=js&"
export * from "./documents-tree-view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64d07b03",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VBtn,VChip,VIcon,VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VListItemTitle,VTreeview})
