<template>
  <div>
    <v-form
      lazy-validation
      ref="form"
      v-model="isValid"
      @submit.prevent="submit()"
    >
      <div class="text-overline">
        Allgemein
      </div>

      <v-text-field
        v-model="form.name"
        filled
        label="Name"
        :rules="[rules.required, rules.short_text]"
        required
        :counter="280"
      />

      <v-textarea
        v-model="form.description"
        filled
        label="Beschreibung"
        :rules="[rules.long_text]"
        :counter="6000"
      />

      <div class="text-overline mt-4">
        Formularfelder
      </div>
      <util-form-items :formItemsProp="form.form_items" />
      <div class="d-flex justify-end mt-6">
        <v-btn text @click="$emit('closeDialog')" exact>Abbrechen</v-btn>
        <v-btn
          class="ml-2"
          color="primary"
          type="submit"
          :disabled="!isValid"
          :loading="loading"
        >
          Speichern
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  props: ["inquirySectionProp"],
  data: () => ({
    search: "",
    sectionDialog: false,
    inquiryItem: {
      attachable_type: "form",
      attachable_id: "",
      parent_id: "",
      inquiry_id: ""
    },
    form: {
      name: "",
      description: "",
      form_items: []
    },
    loading: false,
    isValid: false,
    inquirySection: {},
    documents: []
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    inquirySectionProp: function(val) {
      this.inquirySection = val;
      this.inquiryItem.inquiry_id = val.inquiry_id;
      this.inquiryItem.parent_id = val.id;
    },
    inquirySection: function(val) {
      this.$emit("pass", val);
    }
  },
  created() {
    this.inquirySection = this.inquirySectionProp;
    this.inquiryItem.inquiry_id = this.inquirySectionProp.inquiry_id;
    this.inquiryItem.parent_id = this.inquirySectionProp.id;
    this.indexDocuments();
  },
  methods: {
    indexDocuments() {
      this.axios
        .get("/documents?tree=true")
        .then(r => {
          this.documents = r.data.documents;
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Abruf der Dokumente ist ein Fehler aufgetreten."
          );
        });
    },
    storeInquiryItem(id) {
      this.inquiryItem.attachable_id = id;
      this.inquirySection = this.inquirySectionProp;
      this.inquiryItem.inquiry_id = this.inquirySectionProp.inquiry_id;
      this.inquiryItem.parent_id = this.inquirySectionProp.id;
      this.axios
        .post("/inquiry-items", {
          inquiryItem: this.inquiryItem
        })
        .then(r => {
          this.inquirySection.children.push(r.data.inquiryItem);
          this.inquiryItem = {};
          this.$emit("closeDialog");
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim hinzufügen des Dokuments ist ein Fehler aufgetreten."
          );
        });
    },

    submit() {
      this.axios
        .post("/forms", {
          form: this.form
        })
        .then(r => {
          this.storeInquiryItem(r.data.id);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>

<style scoped></style>
