import store from "../store";

export default permissions => {
  const userPermissions = store.getters["state/permissions"];
  let canEnter = true;

  if (!userPermissions) {
    return false;
  }

  if (!permissions) {
    return true;
  }

  if (!Array.isArray(permissions)) {
    canEnter = userPermissions.includes(permissions);
  } else {
    permissions.forEach(permission => {
      if (!userPermissions.includes(permission)) {
        canEnter = false;
      }
    });
  }

  return canEnter;
};
