var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('router-link',{staticClass:"custom-link",attrs:{"to":{ name: 'SharesShow', params: { id: _vm.share.id } }}},[_c('v-card',{staticClass:"transition-ease-in-out",attrs:{"height":"100%","elevation":hover ? 12 : 2}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[(
                  _vm.inquiryItem.inquiry &&
                    _vm.inquiryItem.inquiry.customer &&
                    _vm.$hasPermissions('consultant permission')
                )?_c('v-list-item-subtitle',{staticClass:"mb-2"},[_c('v-chip',{staticClass:"mt-1",attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.inquiryItem.inquiry.customer.name)+" "),(_vm.inquiryItem.inquiry.customer.label)?_c('span',{staticClass:"ml-1"},[_vm._v("("+_vm._s(_vm.inquiryItem.inquiry.customer.label)+")")]):_vm._e(),(_vm.inquiryItem.inquiry.customer.customerType)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-avatar',{attrs:{"color":_vm.inquiryItem.inquiry.customer.customerType.color,"size":"10"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.inquiryItem.inquiry.customer.customerType.name))])]):_vm._e()],1)],1):_vm._e(),(
                  !_vm.inquiryItem.inquiry &&
                    _vm.$hasPermissions('consultant permission')
                )?_c('v-list-item-subtitle',[_vm._v(" Anfrage wurde gelöscht ")]):_vm._e(),(_vm.isShare)?_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" Anforderung ")]):_vm._e(),(_vm.inquiryItem.name)?_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.inquiryItem.name)+" ")]):_vm._e(),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.inquiryItem.description)+" ")]),(_vm.isShare)?_c('v-list-item-subtitle',{staticClass:"mt-3"},[_vm._v(" Geteilt am: "+_vm._s(_vm._f("moment")(_vm.share.created_at,"DD.MM.YYYY HH:mm"))+" ")]):_vm._e(),(_vm.isShare)?_c('v-list-item-subtitle',{staticClass:"mt-1"},[_vm._v(" Geteilt durch: "+_vm._s(_vm.share.creator.name)+" ")]):_vm._e()],1)],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }