<template>
  <div>
    <v-card :color="section.color" class="mt-4" :id="'section' + section.id">
      <v-card-title v-if="section.name">
        {{ section.name }}
      </v-card-title>
      <v-card-text>
        <div v-if="section.description" class="mb-4">
          {{ section.description }}
        </div>
        <div v-if="$hasPermissions('consultant permission')">
          <v-dialog
            v-model="section.releaseDialog"
            persistent
            max-width="500px"
            v-if="section.release_state === null"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Freigeben ohne E-Mail
                <v-icon right>mdi-lock-open-outline</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Freigeben</span>
              </v-card-title>
              <v-card-text>
                Sind Sie sich sicher, dass Sie diesen Abschnitt ohne E-Mail
                freigeben wollen?
                <div class="d-flex justify-end mt-4">
                  <v-btn text @click="section.releaseDialog = false">
                    Abbrechen
                  </v-btn>
                  <v-btn
                    class="ml-2"
                    color="primary"
                    dark
                    @click="
                      section.releaseDialog = false;
                      release(section.id, 'released');
                    "
                  >
                    Freigeben ohne E-Mail
                    <v-icon right>
                      mdi-lock-open-outline
                    </v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog
            v-if="section.release_state === null"
            v-model="section.releaseWithDialog"
            persistent
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-2" color="primary" dark v-bind="attrs" v-on="on">
                Freigeben mit E-Mail
                <v-icon right>mdi-lock-open-outline</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Freigeben</span>
              </v-card-title>
              <v-card-text>
                Sind Sie sich sicher, dass Sie diesen Abschnitt mit E-Mail
                freigeben wollen?
                <div class="d-flex justify-end mt-4">
                  <v-btn text @click="section.releaseWithDialog = false">
                    Abbrechen
                  </v-btn>
                  <v-btn
                    class="ml-2"
                    color="primary"
                    dark
                    @click="
                      section.releaseWithDialog = false;
                      release(section.id, 'released_with_email');
                    "
                  >
                    Freigeben mit E-Mail
                    <v-icon right>
                      mdi-lock-open-outline
                    </v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
        <v-chip
          v-if="
            section.release_state === null &&
              $hasPermissions('customer permission')
          "
        >
          Noch nicht freigegeben
        </v-chip>
        <div
          v-if="
            section.release_state !== null ||
              $hasPermissions('consultant permission')
          "
        >
          <v-btn depressed class="mt-6" @click="isExpanded = !isExpanded">
            Unterlagen ({{ section.children.length }})
            <v-icon>{{
              isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-btn>
          <v-expand-transition>
            <div v-show="isExpanded">
              <v-card
                class="mt-4"
                flat
                color="grey lighten-4"
                v-for="child in section.children"
                :key="child.id"
              >
                <v-list-item>
                  <v-list-item-avatar>
                    <v-tooltip top v-if="child.current_state === null">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="warning">
                          mdi-progress-upload
                        </v-icon>
                      </template>
                      <span>Ist hochzuladen</span>
                    </v-tooltip>

                    <v-tooltip
                      top
                      v-else-if="child.current_state === 'received'"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="info">
                          mdi-progress-clock
                        </v-icon>
                      </template>
                      <span>Wird geprüft</span>
                    </v-tooltip>

                    <v-tooltip
                      top
                      v-else-if="child.current_state === 'accepted'"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="success">
                          mdi-progress-check
                        </v-icon>
                      </template>
                      <span>Wurde erfolgreich geprüft</span>
                    </v-tooltip>

                    <v-tooltip
                      top
                      v-else-if="child.current_state === 'declined'"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="error">
                          mdi-progress-alert
                        </v-icon>
                      </template>
                      <span>Wurde nicht erfolgreich geprüft</span>
                    </v-tooltip>

                    <v-tooltip
                      top
                      v-else-if="
                        !child.current_state &&
                          child.attachable_type === 'App\\Models\\Form'
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="warning">
                          mdi-progress-question
                        </v-icon>
                      </template>
                      <span>Das Formular ist auszufüllen</span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                          mdi-progress-download
                        </v-icon>
                      </template>
                      <span>Ist herunterzuladen</span>
                    </v-tooltip>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-chip label small v-if="child.current_state === null">
                        Dokument ist hochzuladen
                      </v-chip>
                      <v-chip
                        label
                        small
                        v-else-if="child.current_state === 'received'"
                      >
                        Dokument wird geprüft
                      </v-chip>
                      <v-chip
                        label
                        small
                        v-else-if="child.current_state === 'accepted'"
                      >
                        Dokument wurde erfolgreich geprüft
                      </v-chip>
                      <v-chip
                        label
                        small
                        v-else-if="child.current_state === 'received'"
                      >
                        Dokument wurde nicht erfolgreich geprüft
                      </v-chip>
                      <v-chip
                        label
                        small
                        v-else-if="
                          !child.current_state &&
                            child.attachable_type === 'App\\Models\\Form'
                        "
                      >
                        Das Formular ist auszufüllen
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-title class="mt-3" v-if="child.name">{{
                      child.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ child.description }}
                    </v-list-item-subtitle>
                    <v-list-item-title class="mt-3">
                      <v-btn
                        color="grey darken-2"
                        depressed
                        dark
                        small
                        v-if="
                          child.current_state === false &&
                            child.attachable_type === 'App\\Models\\Document'
                        "
                        @click="
                          downloadFile(
                            child.attachable.upload.id,
                            child.attachable.upload.filename_original
                          )
                        "
                      >
                        Herunterladen
                        <v-icon right>mdi-cloud-download</v-icon>
                      </v-btn>
                      <v-btn
                        color="grey darken-2"
                        depressed
                        dark
                        small
                        v-else-if="
                          child.current_state === null &&
                            child.attachable_type === 'App\\Models\\Document'
                        "
                        :to="{
                          name: 'InquiryItemsShow',
                          params: { id: child.id }
                        }"
                      >
                        {{
                          $hasPermissions("consultant permission")
                            ? "Ansehen"
                            : "Hochladen"
                        }}
                        <v-icon right>
                          {{
                            $hasPermissions("consultant permission")
                              ? "mdi-eye"
                              : "mdi-upload"
                          }}
                        </v-icon>
                      </v-btn>
                      <v-btn
                        color="grey darken-2"
                        depressed
                        dark
                        small
                        v-else-if="
                          child.attachable_type === 'App\\Models\\Document'
                        "
                        :to="{
                          name: 'InquiryItemsShow',
                          params: { id: child.id }
                        }"
                      >
                        {{ "Ansehen" }}
                        <v-icon right>
                          {{ "mdi-eye" }}
                        </v-icon>
                      </v-btn>
                      <v-btn
                        color="grey darken-2"
                        depressed
                        dark
                        small
                        v-else-if="
                          child.attachable_type === 'App\\Models\\Form'
                        "
                        :to="{
                          name: 'InquiryItemsShow',
                          params: { id: child.id }
                        }"
                      >
                        Ausfüllen
                        <v-icon right>mdi-pencil</v-icon>
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action
                    v-if="child.notifications && child.notifications.length > 0"
                  >
                    <v-chip color="primary" dark>
                      {{ child.notifications.length }} Neu
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
              </v-card>
            </div>
          </v-expand-transition>
          <v-row
            class="mt-4"
            v-if="
              $hasPermissions('consultant permission') && section.is_editable
            "
          >
            <v-col cols="12" md="6">
              <v-dialog
                v-model="section.documentDialog"
                persistent
                max-width="800px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed block v-bind="attrs" v-on="on">
                    Dokument hinzufügen
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    Dokument hinzufügen
                  </v-card-title>
                  <v-card-text>
                    <util-inquiry-inquiry-item-document
                      :inquirySectionProp="section"
                      @closeDialog="
                        section.documentDialog = false;
                        section.documentDialogKey = 0;
                        section.documentDialogKey++;
                      "
                      :key="section.documentDialogKey"
                    />
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="6">
              <v-dialog
                v-model="section.formDialog"
                persistent
                max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed block v-bind="attrs" v-on="on">
                    Formular hinzufügen
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    Formular hinzufügen
                  </v-card-title>
                  <v-card-text>
                    <util-inquiry-inquiry-item-form
                      :inquirySectionProp="section"
                      @closeDialog="section.formDialog = false"
                    />
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["inquirySectionProp", "isShare"],
  data: () => ({
    section: {},
    isExpanded: false
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    inquirySectionProp: function(val) {
      this.section = val;
    },
    section: function(val) {
      this.$emit("pass", val);
    }
  },
  created() {
    this.section = this.inquirySectionProp;
  },
  methods: {
    release(inquiryItemId, release_state) {
      this.axios
        .patch("/inquiry-items/release/" + inquiryItemId + "/" + release_state)
        .then(() => {
          this.$snotify.success("Der Abschnitt wurde erfolgreich freigegeben.");
          this.section.release_state = release_state;
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Bei der Freigabe des Abschnitts ist ein Fehler aufgetreten."
          );
        });
    },
    downloadFile(id, name) {
      this.fileLoading = true;
      this.axios
        .get("/upload/" + id + "/download", {
          responseType: "blob"
        })
        .then(r => {
          const url = URL.createObjectURL(new Blob([r.data]));
          const fileName = name;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.fileLoading = false;
        });
    }
  }
};
</script>

<style scoped></style>
