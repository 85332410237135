<template>
  <div style="height: 100%">
    <v-hover v-slot="{ hover }">
      <router-link
        :to="{ name: 'SharesShow', params: { id: share.id } }"
        class="custom-link"
      >
        <v-card
          height="100%"
          :elevation="hover ? 12 : 2"
          class="transition-ease-in-out"
        >
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle
                  v-if="
                    inquiryItem.inquiry &&
                      inquiryItem.inquiry.customer &&
                      $hasPermissions('consultant permission')
                  "
                  class="mb-2"
                >
                  <v-chip label small class="mt-1">
                    {{ inquiryItem.inquiry.customer.name }}
                    <span v-if="inquiryItem.inquiry.customer.label" class="ml-1"
                      >({{ inquiryItem.inquiry.customer.label }})</span
                    >
                    <v-tooltip
                      top
                      v-if="inquiryItem.inquiry.customer.customerType"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" small>
                          <v-avatar
                            :color="
                              inquiryItem.inquiry.customer.customerType.color
                            "
                            size="10"
                          ></v-avatar>
                        </v-btn>
                      </template>
                      <span>{{
                        inquiryItem.inquiry.customer.customerType.name
                      }}</span>
                    </v-tooltip>
                  </v-chip>
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="
                    !inquiryItem.inquiry &&
                      $hasPermissions('consultant permission')
                  "
                >
                  Anfrage wurde gelöscht
                </v-list-item-subtitle>
                <v-list-item-title class="font-weight-bold" v-if="isShare">
                  Anforderung
                </v-list-item-title>
                <v-list-item-title v-if="inquiryItem.name">
                  {{ inquiryItem.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ inquiryItem.description }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="mt-3" v-if="isShare">
                  Geteilt am:
                  {{ share.created_at | moment("DD.MM.YYYY HH:mm") }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="mt-1" v-if="isShare">
                  Geteilt durch:
                  {{ share.creator.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <!--          <v-card-text>
            <v-btn
              block
              depressed
              :to="{ name: 'SharesShow', params: { id: inquiry.id } }"
            >
              Ansehen
              <v-icon right>mdi-eye</v-icon>
            </v-btn>
          </v-card-text>-->
        </v-card>
      </router-link>
    </v-hover>
  </div>
</template>

<script>
export default {
  props: { inquiryItemProp: Object, isShare: Boolean, share: Object },
  data: () => ({
    inquiryItem: {
      name: "",
      description: "",
      hint: "",
      help: ""
    }
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    },
    userDetails() {
      return this.$store.getters["state/userDetails"];
    }
  },
  watch: {
    inquiryItemProp(val) {
      this.inquiryItem = val;
    },
    inquiryItem(val) {
      this.$emit("updateInquiryItem", val);
    }
  },
  created() {
    this.inquiryItem = this.inquiryItemProp;
  },
  methods: {}
};
</script>

<style scoped></style>
