<template>
  <div>
    <v-form lazy-validation ref="form" v-model="isValid">
      <div v-if="!isTemplate">
        <div class="text-overline">
          Zuweisung

          <util-info-tooltip>
            <template v-slot:text>
              Wählen Sie hier aus, um welche(n) Kunden/Kundin es sich handelt
              und ob die Anfrage von einem/einer einzelnen Berater*in oder einem
              Team bearbeitet wird. Sowohl Berater*in als auch Team können genau
              festgelegt werden.
            </template>
          </util-info-tooltip>
        </div>

        <v-autocomplete
          v-model="inquiry.customer_id"
          :items="users_extern"
          filled
          label="Kunde"
          hide-no-data
          :rules="[rules.required]"
          required
          item-text="name"
          item-value="id"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="customerDialog = true">
              <v-list-item-avatar>
                <v-icon>mdi-plus</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  Neuen Kunden erstellen
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.name }}</v-list-item-title>
              <v-list-item-subtitle v-if="data.item.label">
                Kundennummer: {{ data.item.label }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="data.item.customerType_name">
                <v-chip x-small>{{ data.item.customerType_name }}</v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-dialog
                v-model="data.item.editDialog"
                persistent
                max-width="700px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed v-bind="attrs" v-on="on">
                    Bearbeiten
                    <v-icon right>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    <util-user-form
                      type="dialog"
                      v-on:closeDialog="
                        data.item.editDialog = false;
                        customerEditDialogKey++;
                      "
                      v-on:patchUser="
                        data.item.editDialog = false;
                        inquiry.customer_id = $event;
                        indexUsers();
                        customerEditDialogKey++;
                      "
                      :userId="data.item.id"
                      :key="customerEditDialogKey"
                    />
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-list-item-action>
          </template>
        </v-autocomplete>

        <v-select
          v-model="inquiry.assignable_type"
          @input="setAssignee()"
          filled
          :items="[
            { text: 'Berater', value: 'App\\Models\\User' },
            { text: 'Team', value: 'App\\Models\\Team' }
          ]"
          label="Bearbeitertyp"
          :rules="[rules.required]"
          required
        />

        <v-autocomplete
          v-if="inquiry.assignable_type === 'App\\Models\\User'"
          v-model="inquiry.assignable_id"
          :items="users_intern"
          filled
          label="Berater"
          :rules="[rules.required]"
          required
          item-text="name"
          item-value="id"
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.name }}</v-list-item-title>
              <!--              <v-list-item-subtitle v-if="data.item.label">
                {{ data.item.label }}
              </v-list-item-subtitle>-->
              <v-list-item-subtitle v-if="data.item.email">
                {{ data.item.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-autocomplete
          v-if="inquiry.assignable_type === 'App\\Models\\Team'"
          v-model="inquiry.assignable_id"
          :items="teams"
          filled
          label="Team"
          :rules="[rules.required]"
          required
          item-text="name"
          item-value="id"
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.name }}</v-list-item-title>
              <!--              <v-list-item-subtitle v-if="data.item.label">
                {{ data.item.label }}
              </v-list-item-subtitle>-->
              <v-list-item-subtitle v-if="data.item.description">
                {{ data.item.description }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-autocomplete
          v-if="inquiry.assignable_type === 'App\\Models\\Team'"
          v-model="inquiry.contact_person_id"
          :items="users_intern"
          filled
          label="Ansprechpartner"
          :rules="[rules.required]"
          required
          item-text="name"
          item-value="id"
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.name }}</v-list-item-title>
              <!--              <v-list-item-subtitle v-if="data.item.label">
                {{ data.item.label }}
              </v-list-item-subtitle>-->
              <v-list-item-subtitle v-if="data.item.email">
                {{ data.item.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </div>

      <div class="text-overline mt-4">
        Allgemein

        <util-info-tooltip>
          <template v-slot:text>
            Tragen Sie hier eine kurze Bezeichnung ein, worum es sich bei der
            Anfrage handelt.
          </template>
        </util-info-tooltip>
      </div>
      <v-text-field
        v-model="inquiry.name"
        filled
        label="Anliegen"
        :rules="[rules.required, rules.short_text]"
        :counter="280"
      />

      <div v-if="isContinued || inquiry.id">
        <div>
          <v-textarea
            v-model="inquiry.description"
            filled
            label="Beschreibung"
            :rules="[rules.long_text]"
            :counter="6000"
          />

          <div class="text-overline mt-4">
            Hilfestellung

            <util-info-tooltip>
              <template v-slot:text>
                In der Hilfestellung können Sie jeweils eine Bearbeitungsnotiz
                für Berater*in und Kunde/Kundin hinterlegen. Geben Sie hier
                Hinweise, was entweder der/die Berater*in bei der Bearbeitung
                oder der/die Kunde/Kundin bei der Einreichung beachten sollte.
                Der Kunde/die Kundin kann nur die Bearbeitungsnotiz für den
                Kunden einsehen, der/die Berater*in sieht jedoch sowohl die
                Bearbeitungsnotiz für den Kunden, als auch die Bearbeitungsnotiz
                für den Berater.
              </template>
            </util-info-tooltip>
          </div>

          <v-textarea
            v-model="inquiry.hint"
            filled
            label="Bearbeitungsnotiz für den Berater"
            :rules="[rules.long_text]"
            :counter="6000"
          />

          <v-textarea
            v-model="inquiry.help"
            filled
            label="Bearbeitungsnotiz für den Kunden"
            :rules="[rules.long_text]"
            :counter="6000"
          />
        </div>

        <div v-if="isTemplate">
          <div class="text-overline mt-4">
            Einstellungen
          </div>
          <v-checkbox
            v-model="inquiry.is_extendable"
            label="Vorlage ist erweiter- und editierbar."
          />
        </div>

        <div class="text-overline mt-4">
          Aufbau
        </div>

        <v-autocomplete
          v-if="!isTemplate"
          filled
          label="Vorlage"
          v-model="inquiry.template_id"
          :items="inquiryTemplates"
          item-text="name"
          item-value="id"
          @input="changeInquiryTemplate()"
          :loading="loadingTemplate"
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.name }}</v-list-item-title>
              <v-list-item-subtitle v-if="data.item.description">
                {{ data.item.description }}
              </v-list-item-subtitle>
              <v-list-item-subtitle
                v-if="!data.item.is_extendable"
                class="font-weight-bold"
              >
                Nicht erweiterbar
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
        <!--    Parent: {{ inquiry.inquirySections }}-->
        <util-inquiry-inquiry-sections
          :inquirySectionsProp="inquiry.inquirySections"
          :extendable="inquiry.is_extendable"
          :isTemplate="isTemplate"
        />
      </div>

      <div class="subtitle-2" v-if="!(isContinued || inquiry.id)">
        {{
          isTemplate
            ? "Hinweis: Durch den Klick auf Weiter wird die Vorlage automatisch als Entwurf gespeichert."
            : "Hinweis: Durch den Klick auf Weiter wird die Anfrage automatisch als Entwurf gespeichert."
        }}
      </div>
      <div class="d-flex justify-space-between mt-6">
        <div>
          <div v-if="this.$route.params.id">
            <v-dialog v-model="deleteDialog" persistent max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed v-bind="attrs" v-on="on">
                  Löschen
                  <v-icon color="error" right>mdi-delete</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">
                  Wollen Sie die
                  {{ isTemplate ? "Vorlage" : "Anfrage" }} wirklich löschen?
                </v-card-title>
                <v-card-text>
                  Wenn Sie bestätigen, wird die
                  {{ isTemplate ? "Vorlage" : "Anfrage" }} unwiderruflich
                  gelöscht.
                </v-card-text>
                <v-card-actions class="px-4">
                  <v-spacer />
                  <v-btn text @click="deleteDialog = false">
                    Abbrechen
                  </v-btn>
                  <v-btn
                    color="error"
                    class="ml-2"
                    @click="
                      destroyInquiry();
                      deleteDialog = false;
                    "
                  >
                    Bestätigen
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
        <div>
          <v-btn
            text
            :to="
              isTemplate
                ? { name: 'SettingsInquiries' }
                : {
                    name: 'Inquiries',
                    query: { type: 'assigned', view: 'column' }
                  }
            "
            exact
            >Abbrechen</v-btn
          >
          <v-btn
            @click="submit(false)"
            text
            color="primary"
            class="ml-2"
            v-if="isContinued || inquiry.id"
          >
            Als Entwurf speichern
          </v-btn>
          <v-btn
            class="ml-2"
            color="primary"
            @click="submit(true)"
            :disabled="!isValid"
            :loading="loading"
          >
            {{ isContinued || inquiry.id ? "Fertigstellen" : "Weiter" }}
          </v-btn>
        </div>
      </div>
    </v-form>

    <v-dialog v-model="customerDialog" max-width="700px">
      <v-card>
        <v-card-text>
          <util-user-form
            type="dialog"
            v-on:closeDialog="customerDialog = false"
            v-on:storeUser="
              customerDialog = false;
              inquiry.customer_id = $event;
              indexUsers();
            "
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: { isTemplate: Boolean, inquiryProp: Object },
  data: () => ({
    deleteDialog: false,
    customerEditDialogKey: 0,
    customerDialog: false,
    loadingTemplate: false,
    isContinued: false,
    loading: false,
    isValid: false,
    inquiry: {
      name: "",
      description: "",
      hint: "",
      help: "",
      is_template: "",
      inquirySections: [],
      is_extendable: false
    },
    users_intern: [],
    users_extern: [],
    teams: [],
    inquiryTemplates: []
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    },
    userDetails() {
      return this.$store.getters["state/userDetails"];
    }
  },
  watch: {
    inquiryProp(val) {
      this.inquiry = val;
    },
    inquiry(val) {
      this.$emit("updateInquiry", val);
    }
  },
  created() {
    if (
      this.$route.name === "InquiriesEdit" ||
      this.$route.name === "InquiriesForm"
    ) {
      this.indexInquiryTemplates();
    }
    this.inquiry.is_template = this.isTemplate;

    this.indexUsers();
    this.indexTeams();
    this.getUserIdByLabel();
  },
  methods: {
    indexTeams() {
      this.axios
        .get("/teams")
        .then(res => {
          this.teams = res.data.teams;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    },
    indexUsers() {
      this.axios
        .get("/users")
        .then(res => {
          this.users_intern = res.data.users_intern;
          this.users_extern = res.data.users_extern;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    },

    storeInquiry() {
      if (!this.$route.params.id) {
        this.axios
          .post("/inquiries", {
            inquiry: this.inquiry
          })
          .then(r => {
            this.inquiry.id = r.data.id;
            if (this.isTemplate) {
              this.$router.push({
                name: "SettingsInquiriesEdit",
                params: { id: r.data.id }
              });
            } else {
              this.$router.push({
                name: "InquiriesEdit",
                params: { id: r.data.id }
              });
            }
          })
          .catch(e => {
            console.log(e);
            this.$snotify.error("Ein Fehler ist aufgetreten.");
          });
      }
    },
    patchInquiry(isFinish) {
      this.loading = true;
      let urlAppend = "";
      if (isFinish) {
        urlAppend = "?finish=true";
      }
      this.axios
        .patch("/inquiries/" + this.$route.params.id + urlAppend, {
          inquiry: this.inquiry
        })
        .then(() => {
          if (this.isTemplate) {
            this.$router.push({
              name: "SettingsInquiries"
            });
          } else {
            this.$router.push({
              name: "Inquiries",
              query: { type: "assigned", view: "column" }
            });
          }
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error("Beim Speichern ist ein Fehler aufgetreten.");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    submit(isFinish) {
      if (this.$refs.form.validate()) {
        if (this.$route.params.id) {
          this.patchInquiry(isFinish);
        } else {
          this.isContinued = true;
          this.storeInquiry(isFinish);
        }
      }
    },

    setAssignee() {
      if (this.$route.name === "InquiriesForm") {
        if (this.inquiry.assignable_type === "App\\Models\\User") {
          this.inquiry.assignable_id = this.userDetails.id;
          this.inquiry.contact_person_id = "";
        }
        if (this.inquiry.assignable_type === "App\\Models\\Team") {
          this.inquiry.assignable_id = "";
          this.inquiry.contact_person_id = this.userDetails.id;
        }
      }
    },

    indexInquiryTemplates() {
      this.axios
        .get("/inquiries?is_template=true")
        .then(res => {
          this.inquiryTemplates = res.data.inquiries;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    },

    changeInquiryTemplate() {
      this.loadingTemplate = true;
      this.axios
        .patch(
          "/inquiries/c-t/" + this.inquiry.id + "/" + this.inquiry.template_id
        )
        .then(r => {
          this.inquiry.inquirySections = r.data.inquirySections;
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.loadingTemplate = false;
        });
    },
    destroyInquiry() {
      this.axios
        .delete("/inquiries/" + this.$route.params.id)
        .then(() => {
          if (!this.isTemplate) {
            this.$router.push({
              name: "Inquiries",
              query: { type: "assigned", view: "column" }
            });
            this.$snotify.success("Die Anfrage wurde erfolgreich gelöscht.");
          } else {
            this.$router.push({ name: "SettingsInquiries" });
            this.$snotify.success("Die Vorlage wurde erfolgreich gelöscht.");
          }
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error("Die Anfrage konnte nicht gelöscht werden.");
        });
    },

    getUserIdByLabel() {
      if (this.$route.query.label) {
        this.axios
          .get("/users/check-label/" + this.$route.query.label)
          .then(r => {
            this.inquiry.customer_id = r.data.id;
          })
          .catch(() => {
            this.$snotify.error(
              "Es konnte kein Nutzer der Kundennummer zugeordnet werden."
            );
          });
      }
    }
  }
};
</script>

<style scoped></style>
