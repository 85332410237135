<template>
  <div>
    <v-form @submit.prevent="submit()" :disabled="isShare">
      <div v-for="value in valuesProp" :key="value.id">
        <v-text-field
          v-if="value.formItem.type === 'text-field'"
          :label="value.formItem.name"
          :placeholder="value.formItem.description"
          filled
          v-model="value.value"
        />
      </div>
      <div class="d-flex justify-end mt-6" v-if="!isShare">
        <v-btn class="ml-2" color="primary" type="submit" :loading="loading">
          Speichern
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  props: ["valuesProp", "isShare"],
  data: () => ({
    values: {},
    loading: false
  }),
  created() {
    this.values = this.valuesProp;
  },
  watch: {
    valuesProp(val) {
      this.values = val;
    },
    values(val) {
      this.$emit("pass", val);
    }
  },
  computed: {},
  methods: {
    submit() {
      this.loading = true;
      this.axios
        .patch("/form-item-values/multiple", {
          itemValues: this.values
        })
        .then(() => {
          this.$snotify.success("Die Inhalte wurden erfolgreich gespeichert.");
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Speichern der Inhalte ist ein Fehler aufgetreten."
          );
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
