import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import de from "vuetify/lib/locale/de";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#a4bafb",
        secondary: "#44319b",
        accent: "#82B1FF",
        white: "#FFFAFA"
      }
    }
  },
  lang: {
    locales: { de },
    current: "de"
  },
  icons: {
    iconfont: "mdi"
  }
});
