<template>
  <div>
    <v-treeview
      selected-color="primary"
      :items="documents"
      item-children="documents"
      :search="search"
      v-model="selectedDocuments"
      :selectable="selectable"
      @input="$emit('input', selectedDocuments)"
    >
      <template v-slot:prepend="{ item, open }">
        <v-icon v-if="item.documents">
          {{ open ? "mdi-folder-open" : "mdi-folder" }}
        </v-icon>
        <v-icon v-else>
          mdi-file-document-outline
        </v-icon>
      </template>
      <template v-slot:label="{ item }">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
              <span
                class="grey--text"
                v-if="item.documents && item.documents.length"
                >({{ item.documents.length }})</span
              >
              <!-- TODO: Is outbound-->
              <v-chip
                small
                class="ml-2"
                v-if="item.is_outbound"
                color="primary"
                dark
              >
                <v-icon small left>
                  mdi-share-all-outline
                </v-icon>
                Ausgehend
              </v-chip>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="!select">
            <span v-if="item.documents"> </span>
            <v-btn
              icon
              v-else
              :to="{ name: editRoute, params: { id: item.id } }"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-action v-else-if="!item.documents && !selectable">
            <v-btn icon @click="$emit('select', item.id)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-treeview>
  </div>
</template>

<script>
export default {
  props: [
    "documents",
    "search",
    "selectedDocumentsProp",
    "selectable",
    "editRoute",
    "select"
  ],
  data: () => ({
    selectedDocuments: []
  }),
  mounted() {
    this.selectedDocuments = this.selectedDocumentsProp;
  },
  watch: {
    selectedDocumentsProp: function(val) {
      this.selectedDocuments = val;
    },
    selectedDocuments: function(val) {
      this.$emit("pass", val);
    }
  }
};
</script>

<style scoped></style>
