var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('v-row',{staticStyle:{"height":"100%"},attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"height":"100%"},attrs:{"cols":"12","xl":"3","lg":"4"}},[(_vm.inquiry.assignable)?_c('util-user-card',{attrs:{"userProp":_vm.$hasPermissions('customer permission')
            ? _vm.inquiry.assignable
            : _vm.inquiry.customer}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","xl":"6","lg":"8"}},[(_vm.$hasPermissions('consultant permission'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"primary","size":"40"}},'v-avatar',attrs,false),on),[(!_vm.inquiry.assignable.image_url)?_c('span',[_vm._v(_vm._s(_vm.inquiry.assignable.initials))]):_c('img',{attrs:{"src":_vm.inquiry.assignable.image_url,"alt":"Profilbild"}})])]}}],null,false,4158966947)},[_c('span',[_vm._v("Zugewiesen an: "+_vm._s(_vm.inquiry.assignable.name))])]):_vm._e(),_c('div',{staticClass:"body-1"},[_vm._v(" Letzte Aktualsierung: "+_vm._s(_vm._f("moment")(_vm.inquiry.updated_at,"DD.MM.YYYY HH:mm"))+" ")]),_c('div',{staticClass:"text-h2 mt-2"},[_vm._v(" "+_vm._s(_vm.inquiry.name)+" ")]),_c('div',{staticClass:"body-1 mt-2"},[_vm._v(" "+_vm._s(_vm.inquiry.description)+" ")])],1),_c('v-col',{attrs:{"cols":"12","xl":"9","lg":"12"}},[_c('div',{staticClass:"mt-10"},[_c('div',{staticClass:"text-overline"},[_vm._v(" Anforderungen ")]),_vm._l((_vm.inquiry.inquirySections),function(section){return _c('v-card',{key:section.id,staticClass:"mt-4",attrs:{"color":section.color,"id":'section' + section.id}},[_c('v-card-title',[_vm._v(" "+_vm._s(section.name)+" ")]),_c('v-card-text',[(section.description)?_c('div',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(section.description)+" ")]):_vm._e(),(
                section.release_state === null &&
                  _vm.$hasPermissions('customer permission')
              )?_c('v-chip',[_vm._v(" Noch nicht freigegeben ")]):_vm._e(),(
                section.release_state !== null ||
                  _vm.$hasPermissions('consultant permission')
              )?_c('div',_vm._l((section.children),function(child){return _c('v-card',{key:child.id,staticClass:"mt-4",attrs:{"flat":"","color":"grey lighten-4"}},[_c('v-list-item',[_c('v-list-item-avatar',[(child.current_state === null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" mdi-progress-upload ")])]}}],null,true)},[_c('span',[_vm._v("Ist hochzuladen")])]):(child.current_state === 'received')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"info"}},'v-icon',attrs,false),on),[_vm._v(" mdi-progress-clock ")])]}}],null,true)},[_c('span',[_vm._v("Wird geprüft")])]):(child.current_state === 'accepted')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-progress-check ")])]}}],null,true)},[_c('span',[_vm._v("Wurde erfolgreich geprüft")])]):(child.current_state === 'declined')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-progress-alert ")])]}}],null,true)},[_c('span',[_vm._v("Wurde nicht erfolgreich geprüft")])]):(
                        !child.current_state &&
                          child.attachable_type === 'App\\Models\\Form'
                      )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" mdi-progress-question ")])]}}],null,true)},[_c('span',[_vm._v("Das Formular ist auszufüllen")])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-progress-download ")])]}}],null,true)},[_c('span',[_vm._v("Ist herunterzuladen")])])],1),_c('v-list-item-content',[_c('v-list-item-title',[(child.current_state === null)?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" Dokument ist hochzuladen ")]):(child.current_state === 'received')?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" Dokument wird geprüft ")]):(child.current_state === 'accepted')?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" Dokument wurde erfolgreich geprüft ")]):(child.current_state === 'received')?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" Dokument wurde nicht erfolgreich geprüft ")]):(
                          !child.current_state &&
                            child.attachable_type === 'App\\Models\\Form'
                        )?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" Das Formular ist auszufüllen ")]):_vm._e()],1),_c('v-list-item-title',{staticClass:"mt-3"},[_vm._v(_vm._s(child.name))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(child.description)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(child.name)+" ")]),_c('v-list-item-title',{staticClass:"mt-3"},[(
                          child.current_state === false &&
                            child.attachable_type === 'App\\Models\\Document'
                        )?_c('v-btn',{attrs:{"color":"grey darken-2","depressed":"","dark":"","small":""},on:{"click":function($event){return _vm.downloadFile(
                            child.attachable.upload.id,
                            child.attachable.upload.filename_original
                          )}}},[_vm._v(" Herunterladen "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-cloud-download")])],1):(
                          child.attachable_type === 'App\\Models\\Document'
                        )?_c('v-btn',{attrs:{"color":"grey darken-2","depressed":"","dark":"","small":"","to":{
                          name: 'InquiryItemsShow',
                          params: { id: child.id }
                        }}},[_vm._v(" Ansehen "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-eye")])],1):(
                          child.attachable_type === 'App\\Models\\Form'
                        )?_c('v-btn',{attrs:{"color":"grey darken-2","depressed":"","dark":"","small":"","to":{
                          name: 'InquiryItemsShow',
                          params: { id: child.id }
                        }}},[_vm._v(" Ausfüllen "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-pencil")])],1):_vm._e()],1)],1)],1)],1)}),1):_vm._e()],1)],1)})],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }