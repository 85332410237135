var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","block":"","loading":_vm.loading},on:{"click":_vm.downloadAll}},'v-btn',attrs,false),on),[_vm._v(" Alles downloaden "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-download-outline")])],1)]}}])},[_c('span',[_vm._v("Zuletzt heruntergeladen: "),(
          this.uploadRequest &&
            this.uploadRequest.downloadLog &&
            this.uploadRequest.downloadLog.created_at
        )?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(this.uploadRequest.downloadLog.created_at,"DD.MM.YYYY HH:mm"))+" ")]):_c('span',[_vm._v(" Noch nie ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }