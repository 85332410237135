<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          text
          block
          v-on="on"
          v-bind="attrs"
          @click="downloadAll"
          :loading="loading"
        >
          Alles downloaden
          <v-icon right>mdi-download-outline</v-icon>
        </v-btn>
      </template>
      <span
        >Zuletzt heruntergeladen:
        <span
          v-if="
            this.uploadRequest &&
              this.uploadRequest.downloadLog &&
              this.uploadRequest.downloadLog.created_at
          "
        >
          {{
            this.uploadRequest.downloadLog.created_at
              | moment("DD.MM.YYYY HH:mm")
          }}
        </span>
        <span v-else>
          Noch nie
        </span>
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    uploadRequest: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    // functional
    loading: false
  }),
  computed: {},
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      showInquiryItem: "inquiryItem/show"
    }),

    downloadAll() {
      this.loading = true;
      this.axios
        .get("/upload-requests/download-all/" + this.uploadRequest.id, {
          responseType: "blob"
        })
        .then(r => {
          const url = URL.createObjectURL(new Blob([r.data]));

          const date = this.$moment(this.uploadRequest.created_at).format(
            "YYYY-MM-DD"
          );
          const fileName =
            this.uploadRequest.requestable_name + "_" + date + ".zip";
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.loading = false;
          this.showInquiryItem({ id: this.$route.params.id });
        });
    }
  }
};
</script>

<style scoped></style>
