<template>
  <div>
    <v-row style="height: 100%" no-gutters class="mx-n2 my-n2 my-sm-0">
      <v-col cols="12" lg="4" md="4" sm="4" xs="12" class="px-2 py-2 py-sm-0">
        <dashboard-consultant-open-progress style="height: 100%;" />
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="4" xs="12" class="px-2 py-2 py-sm-0">
        <dashboard-consultant-in-work-progress style="height: 100%;" />
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="4" xs="12" class="px-2 py-2 py-sm-0">
        <dashboard-consultant-finished-progress style="height: 100%;" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
