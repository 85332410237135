<template>
  <div style="height: 100%">
    <v-hover v-slot="{ hover }">
      <router-link
        :to="
          !isShare
            ? isDraft
              ? { name: 'InquiriesEdit', params: { id: inquiry.id } }
              : { name: 'InquiriesShow', params: { id: inquiry.id } }
            : { name: 'SharesShow', params: { id: share.id } }
        "
        class="custom-link"
      >
        <v-card
          height="100%"
          :elevation="hover ? 12 : 2"
          class="transition-ease-in-out"
        >
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-tooltip top :disabled="!inquiry.assignable">
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      v-if="inquiry.assignable"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      class="white--text"
                      size="40"
                    >
                      <span v-if="!inquiry.assignable.image_url">{{
                        inquiry.assignable.initials
                      }}</span>
                      <img
                        v-else
                        :src="inquiry.assignable.image_url"
                        alt="Profilbild"
                      />
                    </v-avatar>
                  </template>
                  <span>{{
                    inquiry.assignable ? inquiry.assignable.name : ""
                  }}</span>
                </v-tooltip>
              </v-list-item-avatar>
              <v-list-item-content class="ml-6 mr-6">
                <v-list-item-subtitle
                  v-if="
                    inquiry.customer && $hasPermissions('consultant permission')
                  "
                  class="mb-2"
                >
                  <v-chip label small color="grey lighten-4" class="mt-1">
                    {{ inquiry.customer.name }}
                    <span v-if="inquiry.customer.label" class="ml-1"
                      >(Kdnr: {{ inquiry.customer.label }})</span
                    >
                    <v-tooltip top v-if="inquiry.customer.customerType">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" small>
                          <v-avatar
                            right
                            :color="inquiry.customer.customerType.color"
                            size="5"
                          ></v-avatar>
                        </v-btn>
                      </template>
                      <span>{{ inquiry.customer.customerType.name }}</span>
                    </v-tooltip>
                  </v-chip>
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="inquiry.contactPerson">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        {{ inquiry.contactPerson.name }}
                      </span>
                    </template>
                    <span>Ansprechpartner</span>
                  </v-tooltip>
                </v-list-item-subtitle>
                <v-list-item-title class="font-weight-bold" v-if="isShare">
                  Anfrage
                </v-list-item-title>
                <v-list-item-title
                  class="font-weight-bold"
                  v-if="inquiry.product"
                >
                  {{ inquiry.product.name }}
                </v-list-item-title>
                <v-list-item-title v-if="inquiry.name" class="font-weight-bold">
                  {{ inquiry.name }}
                </v-list-item-title>
                <v-list-item-title>
                  {{ inquiry.description }}
                </v-list-item-title>
                <v-list-item-subtitle class="mt-1" v-if="!isShare">
                  Aktualisierung
                  <span v-if="inquiry.last_customer_action_at">
                    am
                    {{
                      inquiry.last_customer_action_at
                        | moment("DD.MM.YYYY HH:mm")
                    }}
                  </span>
                  <span v-else class="font-weight-bold">
                    keine
                  </span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="mt-3" v-if="isShare">
                  Geteilt am:
                  {{ share.created_at | moment("DD.MM.YYYY HH:mm") }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="mt-1" v-if="isShare">
                  Geteilt durch:
                  {{ share.creator.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="sortBy">
                <v-chip color="success" v-if="sortBy === 'fulfillment_ratio'">
                  {{ inquiry.fulfilled_requirements }} /
                  {{ inquiry.total_requirements }}
                </v-chip>
                <v-chip
                  :color="inquiry.is_too_long ? 'error' : 'success'"
                  v-if="sortBy === 'last_customer_action_at'"
                >
                  <span v-if="inquiry.last_customer_action_at">
                    {{ inquiry.last_customer_action_at | moment("calendar") }}
                  </span>
                  <span v-else class="font-weight-bold">
                    Keine
                  </span>
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </router-link>
    </v-hover>
  </div>
</template>

<script>
export default {
  props: {
    inquiryProp: Object,
    isShare: Boolean,
    share: Object,
    sortBy: String,
    isDraft: Boolean
  },
  data: () => ({
    inquiry: {
      name: "",
      description: "",
      hint: "",
      help: ""
    }
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    },
    userDetails() {
      return this.$store.getters["state/userDetails"];
    }
  },
  watch: {
    inquiryProp(val) {
      this.inquiry = val;
    },
    inquiry(val) {
      this.$emit("updateInquiry", val);
    }
  },
  created() {
    this.inquiry = this.inquiryProp;
  },
  methods: {}
};
</script>

<style scoped></style>
