<template>
  <div class="mt-n4">
    <!-- Add edit and auto release   -->
    <draggable :list="inquirySections" group="sections">
      <v-card
        class="mt-4 lighten-4"
        v-for="(section, sectionIndex) in inquirySections"
        :key="sectionIndex"
        :color="section.color"
        :disabled="!isTemplate && !section.is_editable"
      >
        <v-card-text
          class="d-flex justify-center"
          v-if="isTemplate || section.is_editable"
        >
          <v-btn icon style="cursor: move;">
            <v-icon>mdi-drag</v-icon>
          </v-btn>
        </v-card-text>
        <v-card-title>
          {{ section.name }}
        </v-card-title>
        <v-card-text>
          {{ section.description }}
          <draggable
            :list="section.children"
            group="children"
            style="min-height: 40px"
            :class="section.children.length < 1 ? 'grey lighten-4 mt-4' : ''"
            @start="dragging = true"
            @end="dragging = false"
            @change="updateSection(section)"
            :disabled="!isTemplate && !section.is_editable"
          >
            <v-card
              class="mt-4"
              flat
              color="grey lighten-4"
              v-for="(child, childIndex) in section.children"
              :key="childIndex"
            >
              <v-card-text
                class="d-flex justify-center"
                v-if="isTemplate || section.is_editable"
              >
                <v-btn icon style="cursor: move;">
                  <v-icon>mdi-drag</v-icon>
                </v-btn>
              </v-card-text>
              <v-card-title>
                {{ child.name }}
              </v-card-title>
              <v-card-subtitle>
                <span v-if="child.attachable_type === 'App\\Models\\Document'">
                  Dokument
                </span>
                <span v-else-if="child.attachable_type === 'App\\Models\\Form'">
                  Formular
                </span>
              </v-card-subtitle>
              <v-card-text>
                <div
                  v-if="child.description"
                  style="overflow: hidden; white-space: nowrap;
                          text-overflow: ellipsis;"
                >
                  {{ child.description }}
                </div>

                <v-btn
                  v-if="isTemplate || section.is_editable"
                  block
                  class="mt-4"
                  text
                  @click="destroyChild(child.id, childIndex, section)"
                >
                  Entfernen
                  <v-icon right>mdi-close</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>
          </draggable>
          <v-row class="mt-4" v-if="isTemplate || section.is_editable">
            <v-col cols="12" md="6">
              <v-dialog
                v-model="section.documentDialog"
                persistent
                max-width="800px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed block v-bind="attrs" v-on="on">
                    Dokument hinzufügen
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    Dokument hinzufügen
                  </v-card-title>
                  <v-card-text>
                    <util-inquiry-inquiry-item-document
                      :inquirySectionProp="section"
                      @closeDialog="
                        section.documentDialog = false;
                        documentDialogKey++;
                      "
                      :key="documentDialogKey"
                    />
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="6">
              <v-dialog
                v-model="section.formDialog"
                persistent
                max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed block v-bind="attrs" v-on="on">
                    Formular hinzufügen
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    Formular hinzufügen
                  </v-card-title>
                  <v-card-text>
                    <util-inquiry-inquiry-item-form
                      :inquirySectionProp="section"
                      @closeDialog="
                        section.formDialog = false;
                        formDialogKey++;
                      "
                      :key="formDialogKey"
                    />
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>

          <v-btn
            block
            class="mt-4"
            text
            @click="destroySection(section.id, sectionIndex)"
          >
            Entfernen
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </draggable>
    <v-btn
      class="mt-3"
      depressed
      block
      @click="sectionDialog = true"
      v-if="
        (inquirySections[0] && inquirySections[0].is_editable) ||
          inquirySections.length < 1 ||
          isTemplate
      "
    >
      Abschnitt hinzufügen
      <v-icon right>mdi-plus</v-icon>
    </v-btn>

    <v-dialog v-model="sectionDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>Abschnitt hinzufügen</v-card-title>
        <v-card-text>
          <v-form
            lazy-validation
            v-model="isValid"
            ref="form"
            @submit.prevent="storeSection"
          >
            <v-text-field
              v-model="section.name"
              filled
              label="Name"
              :rules="[rules.required, rules.short_text]"
              :counter="280"
            />
            <v-textarea
              v-model="section.description"
              filled
              label="Beschreibung"
              :rules="[rules.long_text]"
              :counter="6000"
            />

            <div class="text-overline mt-4">
              Farbe
            </div>
            <v-color-picker
              v-model="section.color"
              mode="rgba"
              show-swatches
              hide-inputs
              hide-sliders
              hide-canvas
              :swatches="swatches"
              width="100%"
            />

            <div class="d-flex justify-end">
              <v-btn text @click="sectionDialog = false">Abbrechen</v-btn>
              <v-btn
                color="primary"
                class="ml-2"
                type="submit"
                :disabled="!isValid"
              >
                Speichern
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable
  },
  props: ["inquirySectionsProp", "extendable", "isTemplate"],
  data: () => ({
    sectionDialog: false,
    inquirySections: [],
    loading: false,
    isValid: false,
    users_intern: [],
    users_extern: [],
    teams: [],
    swatches: [
      ["#ffffff", "#d2d2d2", "#777777"],
      ["#ff0000", "#AA0000", "#550000"],
      ["#FFFF00", "#AAAA00", "#555500"],
      ["#00FF00", "#00AA00", "#005500"],
      ["#00FFFF", "#00AAAA", "#005555"],
      ["#0000FF", "#0000AA", "#000055"]
    ],

    section: {
      color: "#ffffff"
    },
    formDialogKey: 0,
    documentDialogKey: 0
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    inquirySectionsProp: function(val) {
      this.inquirySections = val;
    },
    inquirySections: function(val) {
      this.$emit("pass", val);
    }
  },
  created() {
    this.inquirySections = this.inquirySectionsProp;
  },
  methods: {
    storeSection() {
      let data = this.section;
      data.inquiry_id = this.$route.params.id;

      this.axios
        .post("/inquiry-items", {
          inquiryItem: data
        })
        .then(r => {
          this.inquirySections.push(r.data.inquiryItem);
          this.sectionDialog = false;
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.section = {};
          this.section.color = "#ffffff";
        });
    },
    destroySection(id, index) {
      this.axios
        .delete("/inquiry-items/" + id)
        .then(() => {
          this.inquirySections.splice(index, 1);
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim entfernen des Abschnitts ist ein Fehler aufgetreten."
          );
        });
    },
    destroyChild(id, index, section) {
      this.axios
        .delete("/inquiry-items/" + id)
        .then(() => {
          section.children.splice(index, 1);
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim entfernen der Anforderung ist ein Fehler aufgetreten."
          );
        });
    },

    updateSection(section) {
      this.axios
        .patch("/inquiry-items/" + section.id, {
          inquiryItem: section
        })
        .then(() => {
          //
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>

<style scoped></style>
