export default {
  breadCrumbs: [],
  isDrawerOpen: false,
  isInquiryViewColumn: true,
  filenameExtensions: ["doc", "docx", "jpg", "jpeg", "odt", "pdf", "png"],
  formItemTypes: [
    { text: "Textzeile", value: "text-field" },
    { text: "Textfeld", value: "textarea" },
    { text: "Auswahl", value: "select" }
  ],
  userTitles: ["Dr.", "Dr. Dr.", "Prof.", "Prof. Dr."],
  userTypes: [
    { text: "Extern", value: "extern" },
    { text: "Intern", value: "intern" }
  ],
  rules: {
    required: v => !!v || "Feld wird benötigt.",
    email: v =>
      /.+@.+\..+/.test(v) || "Das Feld benötigt eine gültige E-Mail-Adresse.",
    password_length: v =>
      (v || "").length <= 128 ||
      "Das Feld darf maximal 128 Zeichen beinhalten.",
    phone: v =>
      !v ||
      /(\(?([\d \-)–+/(]+)\)?([ .\-–/]?)([\d]+))/.test(v) ||
      "Das Feld muss eine gültige Telefonnummer beinhalten.",
    short_text: v =>
      (v || "").length <= 280 ||
      "Das Feld darf maximal 280 Zeichen beinhalten.",
    long_text: v =>
      (v || "").length <= 6000 ||
      "Das Feld darf maximal 6000 Zeichen beinhalten.",
    label_text: v =>
      (v || "").length <= 50 || "Das Feld darf maximal 50 Zeichen beinhalten.",
    positiveIntegers: v =>
      !v ||
      /^\+?(0|[1-9]\d*)$/.test(v) ||
      "Dieses Feld darf nur positive Zahlen enthalten."
  }
};
