var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-overline"},[_vm._v("Aufrufe")]),_vm._l((_vm.uploadRequests),function(request,requestIndex){return _c('div',{key:request.id},[_c('v-card',{staticClass:"mt-4",attrs:{"flat":"","color":"grey lighten-4"}},[_c('v-card-text',[(request.name)?_c('div',{staticClass:"text-4 mb-2"},[_vm._v(_vm._s(request.name))]):_vm._e(),(request.description)?_c('div',{staticClass:"body-1 mb-2"},[_vm._v(" "+_vm._s(request.description)+" ")]):_vm._e(),(request.help)?_c('div',{staticClass:"body-1 mb-2"},[_vm._v(" Hinweis: "+_vm._s(request.help)+" ")]):_vm._e(),(request.created_at)?_c('div',{staticClass:"body-1 mb-4"},[(request.state === null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" mdi-progress-upload ")])]}}],null,true)},[_c('span',[_vm._v("Ist hochzuladen")])]):(request.state === 'received')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"info"}},'v-icon',attrs,false),on),[_vm._v(" mdi-progress-clock ")])]}}],null,true)},[_c('span',[_vm._v("Wird geprüft")])]):(request.state === 'accepted')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-progress-check ")])]}}],null,true)},[_c('span',[_vm._v("Wurde erfolgreich geprüft")])]):(request.state === 'declined')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-progress-alert ")])]}}],null,true)},[_c('span',[_vm._v("Wurde nicht erfolgreich geprüft")])]):_vm._e(),_c('span',{staticClass:"ml-2"},[_vm._v("Aufruf vom "+_vm._s(_vm._f("moment")(request.created_at,"DD.MM.YYYY")))])],1):_vm._e(),_c('div',_vm._l((request.uploads),function(upload,uploadIndex){return _c('div',{key:upload.id},[(
                upload.uploader.id === _vm.userDetails.id ||
                  request.state !== null
              )?_c('v-chip',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(upload.filename_original)+" ("+_vm._s(upload.uploader ? upload.uploader.name : "")+") "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.downloadFile(upload.id, upload.filename_original)}}},'v-btn',attrs,false),on),[_c('v-avatar',[_c('v-icon',[_vm._v("mdi-download")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Herunterladen")])]),(request.state === null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteFile(upload.id, uploadIndex, requestIndex)}}},'v-btn',attrs,false),on),[_c('v-avatar',[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Entfernen")])]):_vm._e()],1):_vm._e()],1)}),0),(request.state === null)?_c('div',[_c('util-file-drop',{staticClass:"mt-4",attrs:{"fileProp":_vm.file},on:{"pass":function($event){_vm.file = $event}}}),_c('v-btn',{staticClass:"mt-4",attrs:{"depressed":"","block":"","large":"","loading":_vm.uploadLoading,"disabled":!_vm.file},on:{"click":function($event){return _vm.storeFile(request.id, requestIndex)}},scopedSlots:_vm._u([(_vm.file)?{key:"loader",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.uploadPercentage)+" %")])]},proxy:true}:null],null,true)},[_vm._v(" Hochladen "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-upload")])],1),(request.state === null)?_c('div',{staticClass:"d-flex justify-end mt-4",on:{"click":function($event){return _vm.changeState(request.id, requestIndex, 'received')}}},[_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$hasPermissions("customer permission") ? "Für Berater freigeben" : "Freigeben")+" ")])],1):_vm._e()],1):_vm._e(),(
            request.state !== null &&
              _vm.$hasPermissions('consultant permission') &&
              !_vm.isShare
          )?_c('div',{staticClass:"mt-4 d-flex justify-space-between"},[_c('v-btn',{attrs:{"color":"white","loading":_vm.downloadAllLoading},on:{"click":function($event){return _vm.downloadAll(request)}}},[_vm._v(" Alles herunterladen "),(request.downloadLog)?_c('span',[_vm._v(" (Zuletzt am "+_vm._s(_vm._f("moment")(request.downloadLog.created_at,"DD.MM.YYYY HH:mm"))+") ")]):_vm._e(),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-download ")])],1),_c('div',[_c('v-menu',{attrs:{"bottom":"","transition":"slide-y-transition","open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Status ändern "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"nav":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.changeState(request.id, requestIndex, 'accepted')}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-progress-check")])],1),_c('v-list-item-title',[_vm._v("Annehmen")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.changeState(request.id, requestIndex, 'declined')}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-progress-alert")])],1),_c('v-list-item-title',[_vm._v("Ablehnen")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Erneut anfordern "),_c('v-icon',{attrs:{"right":"","color":"warning"}},[_vm._v("mdi-repeat")])],1)]}}],null,true),model:{value:(request.dialog),callback:function ($$v) {_vm.$set(request, "dialog", $$v)},expression:"request.dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Erneut anfordern")])]),_c('v-card-text',[_c('v-textarea',{attrs:{"label":"Hinweis für Kunden","filled":"","placeholder":"Teilen Sie dem Kunden mit, was zur Ablehnung der eingereichten Unterlagen geführt hat."},model:{value:(_vm.help),callback:function ($$v) {_vm.help=$$v},expression:"help"}}),_c('div',{staticClass:"d-flex justify-end mt-4"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){request.dialog = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){request.dialog = false;
                        _vm.requestAgain(request.id);}}},[_vm._v(" Erneut anfordern "),_c('v-icon',{attrs:{"right":"","color":"warning"}},[_vm._v("mdi-repeat")])],1)],1)],1)],1)],1)],1)],1):_vm._e()])],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }