<template>
  <v-divider class="primary" style="border-width: 3px" />
</template>

<script>
export default {
  props: {},
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
