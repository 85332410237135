<template>
  <Fragment>
    <v-toolbar-title>Collapsing Bar</v-toolbar-title>

    <v-spacer></v-spacer>
    Funktioniert nicht
  </Fragment>
</template>

<script>
export default {
  name: "app-bar-content"
};
</script>

<style scoped></style>
