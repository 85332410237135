export default {
  breadCrumbs(state) {
    return state.breadCrumbs;
  },
  isDrawerOpen(state) {
    return state.isDrawerOpen;
  },
  isLoginForm(state) {
    return state.isLoginForm;
  },
  userTitles(state) {
    return state.userTitles;
  },
  userTypes(state) {
    return state.userTypes;
  },
  rules(state) {
    return state.rules;
  },
  hasHistory() {
    return window.history.length > 2;
  },
  filenameExtensions(state) {
    return state.filenameExtensions;
  },
  formItemTypes(state) {
    return state.formItemTypes;
  }
};
