import store from "../store";

export default roles => {
  const userRoles = store.getters["state/roles"];
  let canEnter = true;

  if (!userRoles) {
    return false;
  }

  if (!roles) {
    return true;
  }

  if (!Array.isArray(roles)) {
    canEnter = userRoles.includes(roles);
  } else {
    roles.forEach(role => {
      if (!userRoles.includes(role)) {
        canEnter = false;
      }
    });
  }

  return canEnter;
};
