<template>
  <div style="height: 100%;">
    <v-row style="height: 100%" justify="center">
      <v-col cols="12" xl="3" lg="4" style="height: 100%">
        <util-user-card
          v-if="inquiry.assignable"
          :userProp="
            $hasPermissions('customer permission')
              ? inquiry.assignable
              : inquiry.customer
          "
        />
      </v-col>
      <v-col cols="12" xl="6" lg="8">
        <v-tooltip top v-if="$hasPermissions('consultant permission')">
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              color="primary"
              v-bind="attrs"
              v-on="on"
              class="white--text"
              size="40"
            >
              <span v-if="!inquiry.assignable.image_url">{{
                inquiry.assignable.initials
              }}</span>
              <img
                v-else
                :src="inquiry.assignable.image_url"
                alt="Profilbild"
              />
            </v-avatar>
          </template>
          <span>Zugewiesen an: {{ inquiry.assignable.name }}</span>
        </v-tooltip>
        <div class="body-1">
          Letzte Aktualsierung:
          {{ inquiry.updated_at | moment("DD.MM.YYYY HH:mm") }}
        </div>
        <div class="text-h2 mt-2">
          {{ inquiry.name }}
        </div>
        <div class="body-1 mt-2">
          {{ inquiry.description }}
        </div>

        <!--        <div class="mt-4" v-if="$hasPermissions('consultant permission')">
          <v-dialog v-model="shareDialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed v-bind="attrs" v-on="on">
                Teilen
                <v-icon right>mdi-share</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="headline">
                Anfrage teilen
              </v-card-title>

              <v-card-text>
                <v-select
                  v-model="share.accessor_id"
                  :items="teams"
                  filled
                  label="Team"
                  :rules="[rules.required]"
                  required
                  item-text="name"
                  item-value="id"
                />
              </v-card-text>

              <v-card-text class="justify-end d-flex">
                <v-btn text @click="shareDialog = false">
                  Abbrechen
                </v-btn>
                <v-btn
                  color="primary"
                  class="ml-2"
                  @click="
                    shareDialog = false;
                    shareInquiry();
                  "
                >
                  Teilen
                </v-btn>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>-->
      </v-col>
      <v-col cols="12" xl="9" lg="12">
        <div class="mt-10">
          <div class="text-overline">
            Anforderungen
          </div>

          <v-card
            v-for="section in inquiry.inquirySections"
            :key="section.id"
            :color="section.color"
            class="mt-4"
            :id="'section' + section.id"
          >
            <v-card-title>
              {{ section.name }}
            </v-card-title>
            <v-card-text>
              <div v-if="section.description" class="mb-4">
                {{ section.description }}
              </div>
              <!--              <div v-if="$hasPermissions('consultant permission')">
                <v-dialog
                  v-model="section.releaseDialog"
                  persistent
                  max-width="500px"
                  v-if="section.release_state === null"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      Freigeben ohne E-Mail
                      <v-icon right>mdi-lock-open-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Freigeben</span>
                    </v-card-title>
                    <v-card-text>
                      Sind Sie sich sicher, dass Sie diesen Abschnitt ohne
                      E-Mail freigeben wollen?
                      <div class="d-flex justify-end mt-4">
                        <v-btn text @click="section.releaseDialog = false">
                          Abbrechen
                        </v-btn>
                        <v-btn
                          class="ml-2"
                          color="primary"
                          dark
                          @click="
                            section.releaseDialog = false;
                            release(section.id, 'released', sectionIndex);
                          "
                        >
                          Freigeben ohne E-Mail
                          <v-icon right>
                            mdi-lock-open-outline
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-dialog
                  v-if="section.release_state === null"
                  v-model="section.releaseWithDialog"
                  persistent
                  max-width="500px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-2"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      Freigeben mit E-Mail
                      <v-icon right>mdi-lock-open-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Freigeben</span>
                    </v-card-title>
                    <v-card-text>
                      Sind Sie sich sicher, dass Sie diesen Abschnitt mit E-Mail
                      freigeben wollen?
                      <div class="d-flex justify-end mt-4">
                        <v-btn text @click="section.releaseWithDialog = false">
                          Abbrechen
                        </v-btn>
                        <v-btn
                          class="ml-2"
                          color="primary"
                          dark
                          @click="
                            section.releaseWithDialog = false;
                            release(
                              section.id,
                              'released_with_email',
                              sectionIndex
                            );
                          "
                        >
                          Freigeben mit E-Mail
                          <v-icon right>
                            mdi-lock-open-outline
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </div>-->
              <v-chip
                v-if="
                  section.release_state === null &&
                    $hasPermissions('customer permission')
                "
              >
                Noch nicht freigegeben
              </v-chip>
              <div
                v-if="
                  section.release_state !== null ||
                    $hasPermissions('consultant permission')
                "
              >
                <v-card
                  class="mt-4"
                  flat
                  color="grey lighten-4"
                  v-for="child in section.children"
                  :key="child.id"
                >
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-tooltip top v-if="child.current_state === null">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="warning">
                            mdi-progress-upload
                          </v-icon>
                        </template>
                        <span>Ist hochzuladen</span>
                      </v-tooltip>

                      <v-tooltip
                        top
                        v-else-if="child.current_state === 'received'"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="info">
                            mdi-progress-clock
                          </v-icon>
                        </template>
                        <span>Wird geprüft</span>
                      </v-tooltip>

                      <v-tooltip
                        top
                        v-else-if="child.current_state === 'accepted'"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="success">
                            mdi-progress-check
                          </v-icon>
                        </template>
                        <span>Wurde erfolgreich geprüft</span>
                      </v-tooltip>

                      <v-tooltip
                        top
                        v-else-if="child.current_state === 'declined'"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="error">
                            mdi-progress-alert
                          </v-icon>
                        </template>
                        <span>Wurde nicht erfolgreich geprüft</span>
                      </v-tooltip>

                      <v-tooltip
                        top
                        v-else-if="
                          !child.current_state &&
                            child.attachable_type === 'App\\Models\\Form'
                        "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="warning">
                            mdi-progress-question
                          </v-icon>
                        </template>
                        <span>Das Formular ist auszufüllen</span>
                      </v-tooltip>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-progress-download
                          </v-icon>
                        </template>
                        <span>Ist herunterzuladen</span>
                      </v-tooltip>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-chip label small v-if="child.current_state === null">
                          Dokument ist hochzuladen
                        </v-chip>
                        <v-chip
                          label
                          small
                          v-else-if="child.current_state === 'received'"
                        >
                          Dokument wird geprüft
                        </v-chip>
                        <v-chip
                          label
                          small
                          v-else-if="child.current_state === 'accepted'"
                        >
                          Dokument wurde erfolgreich geprüft
                        </v-chip>
                        <v-chip
                          label
                          small
                          v-else-if="child.current_state === 'received'"
                        >
                          Dokument wurde nicht erfolgreich geprüft
                        </v-chip>
                        <v-chip
                          label
                          small
                          v-else-if="
                            !child.current_state &&
                              child.attachable_type === 'App\\Models\\Form'
                          "
                        >
                          Das Formular ist auszufüllen
                        </v-chip>
                      </v-list-item-title>
                      <v-list-item-title class="mt-3">{{
                        child.name
                      }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ child.description }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        {{ child.name }}
                      </v-list-item-subtitle>
                      <v-list-item-title class="mt-3">
                        <v-btn
                          color="grey darken-2"
                          depressed
                          dark
                          small
                          v-if="
                            child.current_state === false &&
                              child.attachable_type === 'App\\Models\\Document'
                          "
                          @click="
                            downloadFile(
                              child.attachable.upload.id,
                              child.attachable.upload.filename_original
                            )
                          "
                        >
                          Herunterladen
                          <v-icon right>mdi-cloud-download</v-icon>
                        </v-btn>
                        <v-btn
                          color="grey darken-2"
                          depressed
                          dark
                          small
                          v-else-if="
                            child.attachable_type === 'App\\Models\\Document'
                          "
                          :to="{
                            name: 'InquiryItemsShow',
                            params: { id: child.id }
                          }"
                        >
                          Ansehen
                          <v-icon right>mdi-eye</v-icon>
                        </v-btn>
                        <v-btn
                          color="grey darken-2"
                          depressed
                          dark
                          small
                          v-else-if="
                            child.attachable_type === 'App\\Models\\Form'
                          "
                          :to="{
                            name: 'InquiryItemsShow',
                            params: { id: child.id }
                          }"
                        >
                          Ausfüllen
                          <v-icon right>mdi-pencil</v-icon>
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: { inquiryProp: Object },
  data: () => ({
    loading: false,
    isValid: false,
    inquiry: {},
    users_intern: [],
    shareDialog: false,
    teams: [],
    share: {}
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    /*$route(to) {
      //
    }*/
  },
  created() {
    this.inquiry = this.inquiryProp;
  },
  methods: {
    downloadFile(id, name) {
      this.fileLoading = true;
      this.axios
        .get("/upload/" + id + "/download", {
          responseType: "blob"
        })
        .then(r => {
          const url = URL.createObjectURL(new Blob([r.data]));
          const fileName = name;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.fileLoading = false;
        });
    }
  }
};
</script>

<style scoped></style>
