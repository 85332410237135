var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mt-4",attrs:{"color":_vm.section.color,"id":'section' + _vm.section.id}},[(_vm.section.name)?_c('v-card-title',[_vm._v(" "+_vm._s(_vm.section.name)+" ")]):_vm._e(),_c('v-card-text',[(_vm.section.description)?_c('div',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.section.description)+" ")]):_vm._e(),(_vm.$hasPermissions('consultant permission'))?_c('div',[(_vm.section.release_state === null)?_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Freigeben ohne E-Mail "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-lock-open-outline")])],1)]}}],null,false,4250615292),model:{value:(_vm.section.releaseDialog),callback:function ($$v) {_vm.$set(_vm.section, "releaseDialog", $$v)},expression:"section.releaseDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Freigeben")])]),_c('v-card-text',[_vm._v(" Sind Sie sich sicher, dass Sie diesen Abschnitt ohne E-Mail freigeben wollen? "),_c('div',{staticClass:"d-flex justify-end mt-4"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.section.releaseDialog = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.section.releaseDialog = false;
                    _vm.release(_vm.section.id, 'released');}}},[_vm._v(" Freigeben ohne E-Mail "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-lock-open-outline ")])],1)],1)])],1)],1):_vm._e(),(_vm.section.release_state === null)?_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Freigeben mit E-Mail "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-lock-open-outline")])],1)]}}],null,false,4028971998),model:{value:(_vm.section.releaseWithDialog),callback:function ($$v) {_vm.$set(_vm.section, "releaseWithDialog", $$v)},expression:"section.releaseWithDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Freigeben")])]),_c('v-card-text',[_vm._v(" Sind Sie sich sicher, dass Sie diesen Abschnitt mit E-Mail freigeben wollen? "),_c('div',{staticClass:"d-flex justify-end mt-4"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.section.releaseWithDialog = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.section.releaseWithDialog = false;
                    _vm.release(_vm.section.id, 'released_with_email');}}},[_vm._v(" Freigeben mit E-Mail "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-lock-open-outline ")])],1)],1)])],1)],1):_vm._e()],1):_vm._e(),(
          _vm.section.release_state === null &&
            _vm.$hasPermissions('customer permission')
        )?_c('v-chip',[_vm._v(" Noch nicht freigegeben ")]):_vm._e(),(
          _vm.section.release_state !== null ||
            _vm.$hasPermissions('consultant permission')
        )?_c('div',[_c('v-btn',{staticClass:"mt-6",attrs:{"depressed":""},on:{"click":function($event){_vm.isExpanded = !_vm.isExpanded}}},[_vm._v(" Unterlagen ("+_vm._s(_vm.section.children.length)+") "),_c('v-icon',[_vm._v(_vm._s(_vm.isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"))])],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpanded),expression:"isExpanded"}]},_vm._l((_vm.section.children),function(child){return _c('v-card',{key:child.id,staticClass:"mt-4",attrs:{"flat":"","color":"grey lighten-4"}},[_c('v-list-item',[_c('v-list-item-avatar',[(child.current_state === null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" mdi-progress-upload ")])]}}],null,true)},[_c('span',[_vm._v("Ist hochzuladen")])]):(child.current_state === 'received')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"info"}},'v-icon',attrs,false),on),[_vm._v(" mdi-progress-clock ")])]}}],null,true)},[_c('span',[_vm._v("Wird geprüft")])]):(child.current_state === 'accepted')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-progress-check ")])]}}],null,true)},[_c('span',[_vm._v("Wurde erfolgreich geprüft")])]):(child.current_state === 'declined')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-progress-alert ")])]}}],null,true)},[_c('span',[_vm._v("Wurde nicht erfolgreich geprüft")])]):(
                      !child.current_state &&
                        child.attachable_type === 'App\\Models\\Form'
                    )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" mdi-progress-question ")])]}}],null,true)},[_c('span',[_vm._v("Das Formular ist auszufüllen")])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-progress-download ")])]}}],null,true)},[_c('span',[_vm._v("Ist herunterzuladen")])])],1),_c('v-list-item-content',[_c('v-list-item-title',[(child.current_state === null)?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" Dokument ist hochzuladen ")]):(child.current_state === 'received')?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" Dokument wird geprüft ")]):(child.current_state === 'accepted')?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" Dokument wurde erfolgreich geprüft ")]):(child.current_state === 'received')?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" Dokument wurde nicht erfolgreich geprüft ")]):(
                        !child.current_state &&
                          child.attachable_type === 'App\\Models\\Form'
                      )?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" Das Formular ist auszufüllen ")]):_vm._e()],1),(child.name)?_c('v-list-item-title',{staticClass:"mt-3"},[_vm._v(_vm._s(child.name))]):_vm._e(),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(child.description)+" ")]),_c('v-list-item-title',{staticClass:"mt-3"},[(
                        child.current_state === false &&
                          child.attachable_type === 'App\\Models\\Document'
                      )?_c('v-btn',{attrs:{"color":"grey darken-2","depressed":"","dark":"","small":""},on:{"click":function($event){return _vm.downloadFile(
                          child.attachable.upload.id,
                          child.attachable.upload.filename_original
                        )}}},[_vm._v(" Herunterladen "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-cloud-download")])],1):(
                        child.current_state === null &&
                          child.attachable_type === 'App\\Models\\Document'
                      )?_c('v-btn',{attrs:{"color":"grey darken-2","depressed":"","dark":"","small":"","to":{
                        name: 'InquiryItemsShow',
                        params: { id: child.id }
                      }}},[_vm._v(" "+_vm._s(_vm.$hasPermissions("consultant permission") ? "Ansehen" : "Hochladen")+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" "+_vm._s(_vm.$hasPermissions("consultant permission") ? "mdi-eye" : "mdi-upload")+" ")])],1):(
                        child.attachable_type === 'App\\Models\\Document'
                      )?_c('v-btn',{attrs:{"color":"grey darken-2","depressed":"","dark":"","small":"","to":{
                        name: 'InquiryItemsShow',
                        params: { id: child.id }
                      }}},[_vm._v(" "+_vm._s("Ansehen")+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" "+_vm._s("mdi-eye")+" ")])],1):(
                        child.attachable_type === 'App\\Models\\Form'
                      )?_c('v-btn',{attrs:{"color":"grey darken-2","depressed":"","dark":"","small":"","to":{
                        name: 'InquiryItemsShow',
                        params: { id: child.id }
                      }}},[_vm._v(" Ausfüllen "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-pencil")])],1):_vm._e()],1)],1),(child.notifications && child.notifications.length > 0)?_c('v-list-item-action',[_c('v-chip',{attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(child.notifications.length)+" Neu ")])],1):_vm._e()],1)],1)}),1)]),(
            _vm.$hasPermissions('consultant permission') && _vm.section.is_editable
          )?_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","block":""}},'v-btn',attrs,false),on),[_vm._v(" Dokument hinzufügen ")])]}}],null,false,2197682192),model:{value:(_vm.section.documentDialog),callback:function ($$v) {_vm.$set(_vm.section, "documentDialog", $$v)},expression:"section.documentDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Dokument hinzufügen ")]),_c('v-card-text',[_c('util-inquiry-inquiry-item-document',{key:_vm.section.documentDialogKey,attrs:{"inquirySectionProp":_vm.section},on:{"closeDialog":function($event){_vm.section.documentDialog = false;
                      _vm.section.documentDialogKey = 0;
                      _vm.section.documentDialogKey++;}}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","block":""}},'v-btn',attrs,false),on),[_vm._v(" Formular hinzufügen ")])]}}],null,false,2611427115),model:{value:(_vm.section.formDialog),callback:function ($$v) {_vm.$set(_vm.section, "formDialog", $$v)},expression:"section.formDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Formular hinzufügen ")]),_c('v-card-text',[_c('util-inquiry-inquiry-item-form',{attrs:{"inquirySectionProp":_vm.section},on:{"closeDialog":function($event){_vm.section.formDialog = false}}})],1)],1)],1)],1)],1):_vm._e()],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }