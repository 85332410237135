<template>
  <v-hover v-slot="{ hover }" style="height: 100%">
    <router-link
      :to="{ name: 'Inquiries' }"
      class="custom-link"
      style="height: 100%"
    >
      <v-card
        dark
        color="primary"
        :elevation="hover ? 12 : 2"
        class="transition-ease-in-out d-flex flex-column justify-space-between"
        height="100%"
      >
        <v-card-title>
          <div class="text-center" style="width: 100%">
            {{ title }}
          </div>
        </v-card-title>
        <v-card-text class="mt-4">
          <div class="d-flex justify-center">
            <v-progress-circular
              button
              :rotate="-90"
              color="secondary"
              :size="150"
              :width="20"
              :value="parseInt(progressValue)"
            >
              <span class="white--text text-h5">
                {{ progressText }}
              </span>
            </v-progress-circular>
          </div>
          <slot name="text"></slot>
        </v-card-text>
        <v-card-actions class="px-6 py-4" v-if="$slots.actions">
          <slot name="actions"></slot>
        </v-card-actions>
      </v-card>
    </router-link>
  </v-hover>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "KEIN TITEL GESETZT"
    },
    progressValue: {
      type: Number,
      default: 0
    },
    progressText: {
      type: Number,
      default: 0
    }
  },
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
