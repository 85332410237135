<template>
  <div>
    <v-text-field
      solo
      solo-inverted
      flat
      placeholder="Suchen"
      prepend-inner-icon="mdi-magnify"
      dense
      hide-details
      v-model="search"
    />
    <util-documents-tree-view
      :search="search"
      :selectOne="true"
      @selectOne="storeInquiryItem($event)"
      :documents="documents"
    />
    <div class="d-flex justify-end">
      <v-btn text @click="$emit('closeDialog')">Abbrechen</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: ["inquirySectionProp"],
  data: () => ({
    search: "",
    sectionDialog: false,
    inquiryItem: {
      attachable_type: "document",
      attachable_id: "",
      parent_id: "",
      inquiry_id: ""
    },
    loading: false,
    isValid: false,
    inquirySection: {},
    documents: []
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    inquirySectionProp: function(val) {
      this.inquirySection = val;
      this.inquiryItem.inquiry_id = val.inquiry_id;
      this.inquiryItem.parent_id = val.id;
    },
    inquirySection: function(val) {
      this.$emit("pass", val);
    }
  },
  created() {
    this.inquirySection = this.inquirySectionProp;
    this.inquiryItem.inquiry_id = this.inquirySectionProp.inquiry_id;
    this.inquiryItem.parent_id = this.inquirySectionProp.id;
    this.indexDocuments();
  },
  methods: {
    indexDocuments() {
      this.axios
        .get(
          "/documents?tree=true&inquiry=" + this.inquirySectionProp.inquiry_id
        )
        .then(r => {
          this.documents = r.data.documents;
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Abruf der Dokumente ist ein Fehler aufgetreten."
          );
        });
    },
    storeInquiryItem(id) {
      this.inquiryItem.attachable_id = id;
      this.axios
        .post("/inquiry-items", {
          inquiryItem: this.inquiryItem
        })
        .then(r => {
          this.inquirySection.children.push(r.data.inquiryItem);
          this.$emit("closeDialog");
          this.$snotify.success(
            "Das Dokument wurde erfolgreich der Anfrage hinzugefügt."
          );
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim hinzufügen des Dokuments ist ein Fehler aufgetreten."
          );
        });
    }
  }
};
</script>

<style scoped></style>
