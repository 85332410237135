<template>
  <v-card>
    <v-card-title class="white--text primary">
      {{ title }}
    </v-card-title>
    <ui-secondary-divider />
    <v-card-text class="mt-4">
      <slot name="text"></slot>
    </v-card-text>
    <v-card-actions class="px-6 py-4">
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "KEIN TITEL GESETZT"
    }
  },
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
