<template>
  <div>
    <div class="text-overline">Aufrufe</div>
    <div v-for="(request, requestIndex) in uploadRequests" :key="request.id">
      <v-card flat color="grey lighten-4" class="mt-4">
        <v-card-text>
          <div v-if="request.name" class="text-4 mb-2">{{ request.name }}</div>
          <div v-if="request.description" class="body-1 mb-2">
            {{ request.description }}
          </div>
          <div v-if="request.help" class="body-1 mb-2">
            Hinweis: {{ request.help }}
          </div>
          <div v-if="request.created_at" class="body-1 mb-4">
            <v-tooltip top v-if="request.state === null">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="warning">
                  mdi-progress-upload
                </v-icon>
              </template>
              <span>Ist hochzuladen</span>
            </v-tooltip>

            <v-tooltip top v-else-if="request.state === 'received'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="info">
                  mdi-progress-clock
                </v-icon>
              </template>
              <span>Wird geprüft</span>
            </v-tooltip>

            <v-tooltip top v-else-if="request.state === 'accepted'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="success">
                  mdi-progress-check
                </v-icon>
              </template>
              <span>Wurde erfolgreich geprüft</span>
            </v-tooltip>

            <v-tooltip top v-else-if="request.state === 'declined'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="error">
                  mdi-progress-alert
                </v-icon>
              </template>
              <span>Wurde nicht erfolgreich geprüft</span>
            </v-tooltip>

            <span class="ml-2"
              >Aufruf vom {{ request.created_at | moment("DD.MM.YYYY") }}</span
            >
          </div>
          <div>
            <div
              v-for="(upload, uploadIndex) in request.uploads"
              :key="upload.id"
            >
              <v-chip
                class="mt-3"
                v-if="
                  upload.uploader.id === userDetails.id ||
                    request.state !== null
                "
              >
                {{ upload.filename_original }} ({{
                  upload.uploader ? upload.uploader.name : ""
                }})
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="downloadFile(upload.id, upload.filename_original)"
                    >
                      <v-avatar>
                        <v-icon>mdi-download</v-icon>
                      </v-avatar>
                    </v-btn>
                  </template>
                  <span>Herunterladen</span>
                </v-tooltip>
                <v-tooltip top v-if="request.state === null">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteFile(upload.id, uploadIndex, requestIndex)"
                    >
                      <v-avatar>
                        <v-icon>mdi-close</v-icon>
                      </v-avatar>
                    </v-btn>
                  </template>
                  <span>Entfernen</span>
                </v-tooltip>
              </v-chip>
            </div>
          </div>
          <div v-if="request.state === null">
            <util-file-drop
              :fileProp="file"
              @pass="file = $event"
              class="mt-4"
            />
            <v-btn
              depressed
              block
              large
              class="mt-4"
              :loading="uploadLoading"
              :disabled="!file"
              @click="storeFile(request.id, requestIndex)"
            >
              <template v-slot:loader v-if="file">
                <span>{{ uploadPercentage }} %</span>
              </template>
              Hochladen
              <v-icon right>mdi-upload</v-icon>
            </v-btn>
            <div
              v-if="request.state === null"
              class="d-flex justify-end mt-4"
              @click="changeState(request.id, requestIndex, 'received')"
            >
              <v-btn color="primary">
                {{
                  $hasPermissions("customer permission")
                    ? "Für Berater freigeben"
                    : "Freigeben"
                }}
              </v-btn>
            </div>
          </div>

          <div
            class="mt-4 d-flex justify-space-between"
            v-if="
              request.state !== null &&
                $hasPermissions('consultant permission') &&
                !isShare
            "
          >
            <v-btn
              color="white"
              @click="downloadAll(request)"
              :loading="downloadAllLoading"
            >
              Alles herunterladen
              <span v-if="request.downloadLog">
                (Zuletzt am
                {{
                  request.downloadLog.created_at | moment("DD.MM.YYYY HH:mm")
                }})
              </span>
              <v-icon right>
                mdi-download
              </v-icon>
            </v-btn>
            <div>
              <v-menu
                bottom
                transition="slide-y-transition"
                open-on-hover
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    Status ändern
                    <v-icon right>
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list nav>
                  <v-list-item
                    link
                    @click="changeState(request.id, requestIndex, 'accepted')"
                  >
                    <v-list-item-icon>
                      <v-icon color="success">mdi-progress-check</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Annehmen</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    @click="changeState(request.id, requestIndex, 'declined')"
                  >
                    <v-list-item-icon>
                      <v-icon color="error">mdi-progress-alert</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Ablehnen</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-dialog v-model="request.dialog" persistent max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    Erneut anfordern
                    <v-icon right color="warning">mdi-repeat</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Erneut anfordern</span>
                  </v-card-title>
                  <v-card-text>
                    <v-textarea
                      label="Hinweis für Kunden"
                      filled
                      v-model="help"
                      placeholder="Teilen Sie dem Kunden mit, was zur Ablehnung der eingereichten Unterlagen geführt hat."
                    />
                    <div class="d-flex justify-end mt-4">
                      <v-btn text @click="request.dialog = false">
                        Abbrechen
                      </v-btn>
                      <v-btn
                        class="ml-2"
                        color="primary"
                        dark
                        @click="
                          request.dialog = false;
                          requestAgain(request.id);
                        "
                      >
                        Erneut anfordern
                        <v-icon right color="warning">mdi-repeat</v-icon>
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: ["uploadRequestsProp", "isShare"],
  data: () => ({
    uploadRequests: [],
    loading: false,
    file: "",
    uploadPercentage: 0,
    uploadLoading: false,
    help: "",
    downloadAllLoading: false
  }),
  created() {
    this.uploadRequests = this.uploadRequestsProp;
  },
  watch: {
    uploadRequestsProp(val) {
      this.uploadRequests = val;
    },
    uploadRequests(val) {
      this.$emit("pass", val);
    }
  },
  computed: {
    userDetails() {
      return this.$store.getters["state/userDetails"];
    }
  },
  methods: {
    storeFile(request_id, index) {
      this.uploadLoading = true;
      let formData = new FormData();

      formData.append("file", this.file);

      this.axios
        .post("upload/request/" + request_id, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = Math.round(
              (100 * progressEvent.loaded) / progressEvent.total
            );
          }.bind(this)
        })
        .then(r => {
          this.file = "";
          this.$snotify.success("Das Dokument wurde erfolgreich hochgeladen.");
          this.uploadRequests[index].uploads.push(r.data.upload);
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error("Beim Hochladen ist ein Fehler aufgetreten.");
        })
        .finally(() => {
          this.uploadLoading = false;
        });
    },
    changeState(request_id, index, state) {
      this.uploadRequests[index].loading = true;
      if (this.file) {
        this.storeFile(request_id, index);
      }
      this.axios
        .patch("/upload-requests/" + request_id + "?state=" + state)
        .then(() => {
          this.$snotify.success(
            "Der Status des Aufrufs wurde erfolgreich aktualisiert."
          );
          this.uploadRequests[index].state = state;
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.uploadRequests[index].loading = false;
        });
    },
    downloadFile(id, name) {
      this.fileLoading = true;
      this.axios
        .get("/upload/" + id + "/download", {
          responseType: "blob"
        })
        .then(r => {
          const url = URL.createObjectURL(new Blob([r.data]));
          const fileName = name;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.fileLoading = false;
        });
    },
    // eslint-disable-next-line no-unused-vars
    deleteFile(id, uploadIndex, requestIndex) {
      this.axios
        .delete("/uploads/" + id)
        .then(() => {
          this.$snotify.success("Das Dokument wurde erfolgreich entfernt.");
          this.uploadRequests[requestIndex].uploads.splice(uploadIndex, 1);
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Entfernen des Dokuments ist ein Fehler aufgetreten."
          );
        });
    },

    requestAgain(request_id) {
      this.axios
        .post("/upload-requests/request-again/" + request_id, {
          help: this.help
        })
        .then(r => {
          this.$snotify.success(
            "Die Unterlagen wurden erfolgreich erneut angefordert."
          );
          this.uploadRequests.push(r.data.uploadRequest);
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.help = "";
        });
    },

    downloadAll(uploadRequest) {
      this.downloadAllLoading = true;
      this.axios
        .get("/upload-requests/download-all/" + uploadRequest.id, {
          responseType: "blob"
        })
        .then(r => {
          const url = URL.createObjectURL(new Blob([r.data]));

          const date = this.$moment(uploadRequest.created_at).format(
            "YYYY-MM-DD"
          );
          const fileName = uploadRequest.requestable_name + "_" + date + ".zip";
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.downloadAllLoading = false;
          this.$emit("reload");
        });
    }
  }
};
</script>

<style scoped></style>
